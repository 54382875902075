module Signup

open Fable.Helpers.React
open Fable.Helpers.React.Props
open System
open Fulma.FontAwesome
open Fulma

let view =
  Section.section [] [
    div [] [
        h1 [] [ str "Sign Up for Updates!" ]
    ]
    div [] [
      
      iframe [ Src "https://docs.google.com/forms/d/e/1FAIpQLSdYL-t3xCsPBdeWs8GH1N6nhxbk7AQ8-TxGTgR1_qXwuwYyfg/viewform?embedded=true";
               Style [ Width "100%"; Height 1118; ]
               FrameBorder 0; MarginHeight 0.; MarginWidth 0. ] [ 
             str "Loading…" 
      ]
    ]
  ]